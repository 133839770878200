import type { MouseEventHandler, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Tooltip from '@/components/Tooltip';
import Info from './Info';
import { renderDateWithTime } from '@/utils/time';
import { useDateFormatPreference } from '@/store/useStore';
import { classes, getNameString } from './utils';
import { isBlank } from '@/utils/strings';

export { default as SkeletonDirectoryBlock } from './SkeletonDirectoryBlock';

export type DirectoryBlockProps = {
  active?: boolean;
  name: string;
  label?: string;
  description?: string;
  updatedAt: string;
  renderIcon: (className: string) => ReactNode;
  onSelect: () => void;
  onOpen: () => void;
};

export default function DirectoryBlock({
  active,
  name,
  label,
  description,
  updatedAt,
  renderIcon,
  onSelect,
  onOpen,
}: DirectoryBlockProps) {
  const dateFormat = useDateFormatPreference();

  const handleSelect: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect();
  };

  const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  };

  const renderName = () => {
    const { label, isTruncated } = getNameString(name);
    const content = <div className={classes.name}>{label}</div>;
    if (isTruncated) {
      return <Tooltip title={name}>{content}</Tooltip>;
    } else {
      return content;
    }
  };

  return (
    <div
      className={twMerge(classes.wrapper, active && classes.active)}
      onClick={handleSelect}
      onDoubleClick={handleOpen}>
      <div className={classes.header}>
        <span>{renderName()}</span>
        {!isBlank(description) && <Info description={description!} />}
      </div>
      <div className={classes.iconWrapper}>
        {renderIcon(classes.fileIcon)}
        {label && <div className={classes.childCount}>{label}</div>}
      </div>
      <div className={classes.footer}>
        {renderDateWithTime(updatedAt, dateFormat)}
      </div>
    </div>
  );
}
