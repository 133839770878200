import { type MouseEventHandler, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import type {
  DecoratedFileCollection,
  UndecoratedFile,
  UndecoratedFileCollection,
} from '@witmetrics/api-client';
import FileCollectionDetailsBar from '@/components/FileCollectionDetailsBar';
import FolderIcon from '@/icons/FolderIcon';
import FileIcon from '@/icons/FileIcon';
import DownloadToaster from '@/components/DownloadToaster';
import { showErrorToaster } from '@/utils/toasters';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { SkeletonDirectoryBlock } from '@/components/DirectoryBlock';
import FileBlock from './FileBlock';
import CollectionBlock from './CollectionBlock';
import {
  downloadFileCollection,
  fetchFileVersion,
  openFileDownloadLink,
} from '@/utils/files';
import { sortByUpdatedAt } from '@/utils/arrays';
import Divider from '@mui/material/Divider';
import Title from '@/components/Title';
import FileCollectionDialogs from './FileCollectionDialogs';
import {
  COLLECTION_PREVIEW,
  DELETE_COLLECTION,
  DELETE_FILE,
  EDIT_COLLECTION,
  EDIT_FILE,
  FILE_PREVIEW,
  SHARE_FILE,
} from '@/constants/fileDialogs';
import {
  classes,
  DEFAULT_DIALOGS,
  getSelectedLabel,
  isCollectionsShown,
  isFilesShown,
  isHeaderOpen,
} from './utils';

export type FileCollectionProps = {
  isFetchingData?: boolean;
  parentFileCollectionID?: number;
  fileCollections: DecoratedFileCollection[] | null;
  files: UndecoratedFile[] | null;
  onOpenFileCollection: (fileCollectionID: number) => void;
  onDeleteFileCollection?: (fileCollectionID: number) => void;
  onDeleteFile?: (fileID: number) => void;
};

export default function FileCollection({
  isFetchingData,
  parentFileCollectionID,
  fileCollections,
  files,
  onOpenFileCollection,
  onDeleteFileCollection,
  onDeleteFile,
}: FileCollectionProps) {
  const { onApiError } = useAppState();
  const [dialogs, setDialogs] = useState(DEFAULT_DIALOGS);
  const [selectedCollectionID, setSelectedCollectionID] = useState<number>();
  const [selectedFileID, setSelectedFileID] = useState<number>();
  const [isDownloadToasterOpen, toggleDownloadToaster] = useToggle(false);
  const [isPreparingDownload, togglePreparingDownload] = useToggle(false);
  const [downloadMessage, setDownloadMessage] = useState(
    'Preparing download...'
  );

  useEffect(() => {
    if (selectedCollectionID || selectedFileID) handleCloseSelection();
  }, [parentFileCollectionID]);

  const handleOpenDialog = (dialogName: string) => {
    setDialogs((d) => ({ ...d, [dialogName]: true }));
  };

  const handleCloseDialog = (dialogName: string) => {
    setDialogs((d) => ({ ...d, [dialogName]: false }));
  };

  const handleCloseSelection = () => {
    setDialogs((d) => ({
      ...d,
      [FILE_PREVIEW]: false,
      [COLLECTION_PREVIEW]: false,
    }));
    setSelectedCollectionID(undefined);
    setSelectedFileID(undefined);
  };

  const handleSelectFileCollection = (fileCollectionID: number) => {
    setSelectedCollectionID(fileCollectionID);
    setSelectedFileID(undefined);
  };

  const handleSelectFile = (fileID: number) => {
    setSelectedFileID(fileID);
    setSelectedCollectionID(undefined);
  };

  const handleUnselect: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) handleCloseSelection();
  };

  const handlePreviewCollection = () => {
    setDialogs((d) => ({
      ...d,
      [FILE_PREVIEW]: false,
      [COLLECTION_PREVIEW]: true,
    }));
    setSelectedFileID(undefined);
  };

  const handlePreviewFile = () => {
    setDialogs((d) => ({
      ...d,
      [COLLECTION_PREVIEW]: false,
      [FILE_PREVIEW]: true,
    }));
    setSelectedCollectionID(undefined);
  };

  const handleView = () => {
    if (selectedCollectionID) {
      return handlePreviewCollection();
    } else if (selectedFileID) {
      return handlePreviewFile();
    }
  };

  const handleOpenFileCollection = (fileCollectionID: number) => {
    handleCloseSelection();
    onOpenFileCollection(fileCollectionID);
  };

  const handleFileDownload = async () => {
    try {
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      const selectedFile = files!.find((f) => f.id === selectedFileID)!;
      const fileVersion = await fetchFileVersion(selectedFileID!);
      if (!fileVersion) return showErrorToaster('File version not found');
      await openFileDownloadLink(fileVersion.id, selectedFile.name);
      togglePreparingDownload(false);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading file');
    }
  };

  const handleCollectionDownload = async () => {
    try {
      togglePreparingDownload(true);
      toggleDownloadToaster(true);
      await downloadFileCollection(selectedCollectionID!, setDownloadMessage);
      togglePreparingDownload(false);
    } catch (err) {
      handleCloseDownloadToaster();
      onApiError(err, 'Error downloading collection');
    }
  };

  const handleCloseDownloadToaster = () => {
    togglePreparingDownload(false);
    toggleDownloadToaster(false);
  };

  const handleDownload = () => {
    if (selectedCollectionID) handleCollectionDownload();
    else if (selectedFileID) handleFileDownload();
  };

  const handleShare = () => {
    if (selectedCollectionID) {
      // TODO
      showErrorToaster('Unable to share file collections at this time');
    } else if (selectedFileID) {
      handleOpenDialog(SHARE_FILE);
    }
  };

  const handleMoveTo = () => {
    // TODO
  };

  const handleEdit = () => {
    if (selectedCollectionID) handleOpenDialog(EDIT_COLLECTION);
    else if (selectedFileID) handleOpenDialog(EDIT_FILE);
  };

  const handleDelete = () => {
    if (selectedCollectionID) handleOpenDialog(DELETE_COLLECTION);
    else if (selectedFileID) handleOpenDialog(DELETE_FILE);
  };

  const handleDeleteCollection = () => {
    handleCloseDialog(DELETE_COLLECTION);
    handleCloseSelection();
    if (onDeleteFileCollection) {
      onDeleteFileCollection(selectedCollectionID!);
    }
  };

  const handleDeleteFile = () => {
    handleCloseDialog(DELETE_FILE);
    handleCloseSelection();
    if (onDeleteFile) onDeleteFile(selectedFileID!);
  };

  const renderSelectedLabel = () => {
    let selection: UndecoratedFileCollection | UndecoratedFile | undefined;
    if (fileCollections && selectedCollectionID) {
      selection = fileCollections.find((fc) => fc.id === selectedCollectionID)!;
    } else if (files && selectedFileID) {
      selection = files.find((f) => f.id === selectedFileID)!;
    }
    return selection ? getSelectedLabel([selection]) : '';
  };

  const renderSelectedIcon = (iconClassName: string) => {
    if (selectedCollectionID) {
      return <FolderIcon className={iconClassName} />;
    } else if (selectedFileID) {
      return <FileIcon className={iconClassName} />;
    }
  };

  return (
    <>
      <>
        <FileCollectionDetailsBar
          open={isHeaderOpen(selectedCollectionID, selectedFileID)}
          label={renderSelectedLabel()}
          renderIcon={renderSelectedIcon}
          onDownload={handleDownload}
          onShare={handleShare}
          onView={handleView}
          onMoveTo={handleMoveTo}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onClose={handleCloseSelection}
        />
        {isFetchingData ? (
          <div className={classes.blocksWrapper}>
            {[0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8].map((delay) => (
              <SkeletonDirectoryBlock key={delay} delay={delay} />
            ))}
          </div>
        ) : (
          <div className={classes.content}>
            <div className={classes.sectionWrapper}>
              <div className={classes.titleWrapper} onClick={handleUnselect}>
                <Title size="sm">File Collections</Title>
              </div>
              <div className={classes.blocksWrapper} onClick={handleUnselect}>
                {isCollectionsShown(fileCollections) ? (
                  sortByUpdatedAt(fileCollections!).map((fc) => (
                    <CollectionBlock
                      key={fc.id}
                      active={selectedCollectionID === fc.id}
                      fileCollection={fc}
                      onSelect={handleSelectFileCollection}
                      onOpen={handleOpenFileCollection}
                    />
                  ))
                ) : (
                  <div className={classes.empty} onClick={handleUnselect}>
                    No file collections.
                  </div>
                )}
              </div>
            </div>
            <Divider className={classes.divider} onClick={handleUnselect} />
            <div
              className={twMerge(classes.sectionWrapper, classes.filesWrapper)}
              onClick={handleUnselect}>
              <div className={classes.titleWrapper} onClick={handleUnselect}>
                <Title size="sm">Files</Title>
              </div>
              <div className={classes.blocksWrapper} onClick={handleUnselect}>
                {isFilesShown(files) ? (
                  sortByUpdatedAt(files!).map((f) => (
                    <FileBlock
                      key={f.id}
                      active={selectedFileID === f.id}
                      file={f}
                      onSelect={handleSelectFile}
                      onOpen={handlePreviewFile}
                    />
                  ))
                ) : (
                  <div className={classes.empty}>No files.</div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      <FileCollectionDialogs
        selectedFileID={selectedFileID}
        selectedCollectionID={selectedCollectionID}
        dialogs={dialogs}
        onSetDialogs={setDialogs}
        onDeleteFile={handleDeleteFile}
        onDeleteCollection={handleDeleteCollection}
      />
      <DownloadToaster
        open={isDownloadToasterOpen}
        isLoading={isPreparingDownload}
        message={downloadMessage}
        onClose={handleCloseDownloadToaster}
      />
    </>
  );
}
