import { type DragEventHandler } from 'react';
import type {
  DecoratedFileCollection,
  UndecoratedFile,
} from '@witmetrics/api-client';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import {
  type Directory,
  parseDroppedItems,
  uploadFileToFileCollection,
} from '@/utils/files';
import { addFile as dispatchAddFile } from '@/store/slices/filesSlice';
import { addFileCollectionFile as dispatchAddFileCollectionFile } from '@/store/slices/fileCollectionFilesSlice';
import { addFileVersion as dispatchAddFileVersion } from '@/store/slices/fileVersionsSlice';
import { addFileCollection as dispatchAddFileCollection } from '@/store/slices/fileCollectionsSlice';
import Dialog from '@mui/material/Dialog';
import UploadIcon from '@/icons/UploadIcon';
import { classes, createNewFileCollection } from './utils';

export type FileDropOverlayProps = {
  open: boolean;
  disabled?: boolean;
  parentFileCollectionID: number;
  onClose: () => void;
  onSaveFiles?: (files: UndecoratedFile[]) => void;
  onSaveFileCollections?: (fileCollection: DecoratedFileCollection[]) => void;
};

export default function FileDropOverlay({
  open,
  disabled,
  parentFileCollectionID,
  onSaveFiles,
  onSaveFileCollections,
  onClose,
}: FileDropOverlayProps) {
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();

  const preventDragAction: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    if (disabled) return;
    const directory = await parseDroppedItems(e.dataTransfer.items);
    // TODO: Show progress somehow
    await Promise.all(
      Object.keys(directory).map((key) =>
        uploadDirectory(key, directory[key], parentFileCollectionID)
      )
    );
    onClose();
  };

  // TODO: Why is the return value set as any?
  // TODO: Can this and the version in NewFileMenuButton be abstracted?
  const uploadDirectory: any = async (
    name: string,
    contents: File | Directory,
    parentCollectionID: number
  ) => {
    try {
      if (contents instanceof File) {
        return handleUploadFile(contents, parentCollectionID);
      } else {
        const fileCollection = await handleCreateFileCollection(
          name,
          contents,
          parentCollectionID
        );
        return Object.keys(contents).forEach((key) =>
          uploadDirectory(key, contents[key], fileCollection.id)
        );
      }
    } catch (err) {
      onApiError(err, 'Error uploading directory');
    }
  };

  const handleUploadFile = async (file: File, parentCollectionID: number) => {
    const data = await uploadFileToFileCollection(
      activeAccount!.accountID,
      parentCollectionID,
      file
    );
    dispatch(dispatchAddFile(data.file));
    dispatch(dispatchAddFileCollectionFile(data.fileCollectionFile));
    dispatch(dispatchAddFileVersion(data.fileVersion));
    if (parentCollectionID === parentFileCollectionID) {
      // TODO -- should be able to batch add
      if (onSaveFiles) onSaveFiles([data.file]);
    }
    return data;
  };

  const handleCreateFileCollection = async (
    name: string,
    directory: Directory,
    parentCollectionID: number
  ) => {
    const fileCollection = await createNewFileCollection({
      name,
      directory,
      practiceID: activeAccount!.accountID,
      parentFileCollectionID: parentCollectionID,
    });
    dispatch(dispatchAddFileCollection(fileCollection));
    if (parentCollectionID === parentFileCollectionID) {
      // TODO -- should be able to batch add
      if (onSaveFileCollections) onSaveFileCollections([fileCollection]);
    }
    return fileCollection;
  };

  if (!open) return null;
  return (
    <Dialog
      open
      PaperProps={{
        className: classes.dialog,
        onDragOver: preventDragAction,
        onDrop: handleDrop,
      }}
      onClose={onClose}
      onDragOver={preventDragAction}
      onDrop={preventDragAction}>
      <div className={classes.wrapper}>
        <UploadIcon className={classes.uploadIcon} />
        <div className={classes.message}>Drag & drop your file(s)</div>
      </div>
    </Dialog>
  );
}
