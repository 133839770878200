import { type Dispatch, type SetStateAction } from 'react';
import { Dictionary } from '@/types';
import {
  COLLECTION_PREVIEW,
  DELETE_COLLECTION,
  DELETE_FILE,
  EDIT_COLLECTION,
  EDIT_FILE,
  FILE_PREVIEW,
  SHARE_FILE,
} from '@/constants/fileDialogs';
import FileViewerDialog from '@/dialogs/FileViewerDialog';
import FileCollectionViewerDialog from '@/dialogs/FileCollectionViewerDialog';
import FileCollectionDialog from '@/dialogs/FileCollectionDialog';
import DeleteFileCollectionDialog from '@/dialogs/DeleteFileCollectionDialog';
import FileInfoDialog from '@/dialogs/FileInfoDialog';
import DeleteFileDialog from '@/dialogs/DeleteFileDialog';
import ShareFileDialog from '@/dialogs/ShareFileDialog';

export type FileCollectionDialogsProps = {
  selectedFileID: number | undefined;
  selectedCollectionID: number | undefined;
  dialogs: Dictionary<boolean>;
  onSetDialogs: Dispatch<SetStateAction<Dictionary<boolean>>>;
  onDeleteFile: () => void;
  onDeleteCollection: () => void;
};

export default function FileCollectionDialogs({
  selectedFileID,
  selectedCollectionID,
  dialogs,
  onSetDialogs,
  onDeleteFile,
  onDeleteCollection,
}: FileCollectionDialogsProps) {
  const handleCloseDialog = (dialogName: string) => {
    onSetDialogs((d) => ({ ...d, [dialogName]: false }));
  };

  const handleCloseFilePreview = () => handleCloseDialog(FILE_PREVIEW);

  const handleCloseCollectionPreview = () => {
    return handleCloseDialog(COLLECTION_PREVIEW);
  };

  const handleCloseEditCollection = () => handleCloseDialog(DELETE_COLLECTION);

  const handleCloseDeleteCollection = () => {
    return handleCloseDialog(DELETE_COLLECTION);
  };

  const handleCloseEditFile = () => handleCloseDialog(EDIT_FILE);

  const handleCloseDeleteFile = () => handleCloseDialog(DELETE_FILE);

  const handleCloseShareFile = () => handleCloseDialog(SHARE_FILE);

  return (
    <>
      {dialogs[FILE_PREVIEW] && selectedFileID && (
        <FileViewerDialog
          fileID={selectedFileID}
          onDelete={onDeleteFile}
          onClose={handleCloseFilePreview}
        />
      )}
      {dialogs[COLLECTION_PREVIEW] && selectedCollectionID && (
        <FileCollectionViewerDialog
          fileCollectionID={selectedCollectionID}
          onDelete={onDeleteCollection}
          onClose={handleCloseCollectionPreview}
        />
      )}
      {dialogs[EDIT_COLLECTION] && (
        <FileCollectionDialog
          fileCollectionID={selectedCollectionID!}
          onClose={handleCloseEditCollection}
        />
      )}
      {dialogs[DELETE_COLLECTION] && (
        <DeleteFileCollectionDialog
          fileCollectionID={selectedCollectionID!}
          onDelete={onDeleteCollection}
          onClose={handleCloseDeleteCollection}
        />
      )}
      {dialogs[EDIT_FILE] && (
        <FileInfoDialog
          fileID={selectedFileID!}
          onClose={handleCloseEditFile}
        />
      )}
      {dialogs[DELETE_FILE] && (
        <DeleteFileDialog
          fileID={selectedFileID!}
          onDelete={onDeleteFile}
          onClose={handleCloseDeleteFile}
        />
      )}
      {dialogs[SHARE_FILE] && (
        <ShareFileDialog
          fileID={selectedFileID!}
          onClose={handleCloseShareFile}
        />
      )}
    </>
  );
}
