import type {
  UndecoratedFile,
  UndecoratedFileCollection,
} from '@witmetrics/api-client';
import { truncateString } from '@/utils/strings';
import type { Dictionary } from '@/types';
import { FILE_DIALOGS } from '@/constants/fileDialogs';

export const classes = {
  content: 'grow flex flex-col min-h-0 overflow-auto',
  titleWrapper: 'w-full flex justify-start items-center',
  sectionWrapper: 'px-4',
  filesWrapper: 'px-4 grow',
  divider: 'm-3 py-4',
  blocksWrapper:
    'grow flex justify-start content-start flex-wrap gap-6 px-2 py-6',
  empty:
    'w-full h-full flex justify-center items-start pt-6 pb-3 text-xl text-grey-6 font-normal',
};

export const DEFAULT_DIALOGS: Dictionary<boolean> = {
  [FILE_DIALOGS.FILE_PREVIEW]: false,
  [FILE_DIALOGS.COLLECTION_PREVIEW]: false,
  [FILE_DIALOGS.EDIT_FILE]: false,
  [FILE_DIALOGS.EDIT_COLLECTION]: false,
  [FILE_DIALOGS.DELETE_FILE]: false,
  [FILE_DIALOGS.DELETE_COLLECTION]: false,
  [FILE_DIALOGS.SHARE_FILE]: false,
};

export function getSelectedLabel(
  selections: (UndecoratedFileCollection | UndecoratedFile)[]
) {
  return selections.length === 1
    ? truncateString(selections[0].name, 50)
    : `${selections.length} items`;
}

export function isCollectionsShown(
  fileCollections: UndecoratedFileCollection[] | null
) {
  return !!fileCollections && fileCollections.length > 0;
}

export function isFilesShown(files: UndecoratedFile[] | null) {
  return !!files && files.length > 0;
}

export function isHeaderOpen(
  selectedFileCollectionID: number | undefined,
  selectedFileID: number | undefined
) {
  return !!selectedFileCollectionID || !!selectedFileID;
}
