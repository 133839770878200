import { useRef, useState } from 'react';
import type {
  DecoratedFileCollection,
  UndecoratedFile,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { Directory, uploadFileToFileCollection } from '@/utils/files';
import { addFileCollection as dispatchAddFileCollection } from '@/store/slices/fileCollectionsSlice';
import {
  addFile as dispatchAddFile,
  setFiles as dispatchSetFiles,
} from '@/store/slices/filesSlice';
import {
  addFileCollectionFile as dispatchAddFileCollectionFile,
  setFileCollectionFiles as dispatchSetFileCollectionFiles,
} from '@/store/slices/fileCollectionFilesSlice';
import {
  addFileVersion as dispatchAddFileVersion,
  setFileVersions as dispatchSetFileVersions,
} from '@/store/slices/fileVersionsSlice';
import IconButton from '@/components/IconButton';
import AddIcon from '@/icons/AddIcon';
import NewFileMenu from './NewFileMenu';
import FileCollectionDialog from '@/dialogs/FileCollectionDialog';
import UploadToaster from '@/components/UploadToaster';
import { showErrorToaster } from '@/utils/toasters';
import { mapToKey } from '@/utils/arrays';
import { uploadFiles } from './utils';

export type NewFileMenuButtonProps = {
  parentFileCollectionID?: number;
  onSaveFiles?: (files: UndecoratedFile[]) => void;
  onSaveFileCollections?: (fileCollection: DecoratedFileCollection[]) => void;
};

export default function NewFileMenuButton({
  parentFileCollectionID,
  onSaveFiles,
  onSaveFileCollections,
}: NewFileMenuButtonProps) {
  const dispatch = useDispatch();
  const menuToggleRef = useRef(null);
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const [filesUploading, setFilesUploading] = useState<string[]>([]);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const [isUploadToasterOpen, toggleUploadToaster] = useToggle(false);
  const [isCollectionDialogOpen, toggleCollectionDialog] = useToggle(false);

  const handleSelectFiles = async (files: File[]) => {
    try {
      toggleMenu(false);
      if (!parentFileCollectionID) {
        return showErrorToaster('Error uploading files');
      }
      setFilesUploading(mapToKey(files, 'name'));
      toggleUploadToaster(true);
      const data = await uploadFiles(
        activeAccount!.accountID,
        parentFileCollectionID,
        files
      );
      dispatch(dispatchSetFiles(data.files));
      dispatch(dispatchSetFileCollectionFiles(data.fileCollectionFiles));
      dispatch(dispatchSetFileVersions(data.fileVersions));
      if (onSaveFiles) onSaveFiles(data.files);
      toggleUploadToaster(false);
      setFilesUploading([]);
    } catch (err) {
      toggleUploadToaster(false);
      setFilesUploading([]);
      onApiError(err, 'Error uploading files');
    }
  };

  const handleSelectDirectory = (directory: Directory) => {
    // TODO: Show upload progress
    toggleMenu(false);
    return Object.keys(directory).forEach((key) =>
      uploadDirectory(key, directory[key], parentFileCollectionID)
    );
  };

  // TODO: Why is the return value set as any?
  const uploadDirectory: any = async (
    name: string,
    contents: File | Directory,
    parentCollectionID?: number
  ) => {
    try {
      if (contents instanceof File) {
        if (!parentCollectionID) {
          throw new Error('Missing required parentCollectionID');
        }
        return handleUploadFile(contents, parentCollectionID);
      } else {
        const fileCollection = await handleCreateFileCollection(
          name,
          contents,
          parentCollectionID
        );
        return Object.keys(contents).forEach((key) =>
          uploadDirectory(key, contents[key], fileCollection.id)
        );
      }
    } catch (err) {
      onApiError(err, 'Error uploading directory');
    }
  };

  const handleUploadFile = async (file: File, parentCollectionID: number) => {
    const data = await uploadFileToFileCollection(
      activeAccount!.accountID,
      parentCollectionID,
      file
    );
    dispatch(dispatchAddFile(data.file));
    dispatch(dispatchAddFileCollectionFile(data.fileCollectionFile));
    dispatch(dispatchAddFileVersion(data.fileVersion));
    if (parentCollectionID === parentFileCollectionID) {
      // TODO -- should be able to batch add
      if (onSaveFiles) onSaveFiles([data.file]);
    }
    return data;
  };

  const handleCreateFileCollection = async (
    name: string,
    directory: Directory,
    parentCollectionID?: number
  ) => {
    let newCollection = await API.FileCollections.createNewFileCollection({
      name,
      practiceID: activeAccount!.accountID,
      parentFileCollectionID: parentCollectionID,
    });
    let childFiles = 0;
    let childFileCollections = 0;
    Object.keys(directory).forEach((key) => {
      if (directory[key] instanceof File) childFiles++;
      else childFileCollections++;
    });
    const fileCollection = {
      ...newCollection,
      childFiles,
      childFileCollections,
    };
    dispatch(dispatchAddFileCollection(fileCollection));
    if (parentCollectionID === parentFileCollectionID) {
      // TODO -- should be able to batch add
      if (onSaveFileCollections) onSaveFileCollections([fileCollection]);
    }
    return fileCollection;
  };

  const handleStartFileCollection = () => {
    toggleMenu(false);
    toggleCollectionDialog(true);
  };

  const handleSaveFileCollection = (
    fileCollection: DecoratedFileCollection
  ) => {
    toggleCollectionDialog(false);
    if (onSaveFileCollections) onSaveFileCollections([fileCollection]);
  };

  return (
    <>
      <IconButton ref={menuToggleRef} onClick={() => toggleMenu()}>
        <AddIcon />
      </IconButton>
      {isMenuOpen && (
        <NewFileMenu
          open={isMenuOpen}
          disableAddFiles={!parentFileCollectionID}
          anchorTo={menuToggleRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onAddFiles={handleSelectFiles}
          onAddDirectory={handleSelectDirectory}
          onAddFileCollection={handleStartFileCollection}
          onClose={toggleMenu}
        />
      )}
      {isCollectionDialogOpen && (
        <FileCollectionDialog
          parentFileCollectionID={parentFileCollectionID}
          onSave={handleSaveFileCollection}
          onClose={() => toggleCollectionDialog(false)}
        />
      )}
      <UploadToaster
        open={isUploadToasterOpen}
        files={filesUploading}
        onClose={toggleUploadToaster}
      />
    </>
  );
}
