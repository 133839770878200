import { API } from '@/api';
import { type Directory } from '@/utils/files';

export const classes = {
  dialog: 'rounded-2xl',
  wrapper:
    'flex flex-col justify-center items-center pt-12 pb-6 px-24 m-4 border-2 border-dashed border-purple-2 rounded-2xl',
  uploadIcon: 'text-purple-2 text-[5rem]',
  message: 'text-lg text-grey-7 text-center my-4',
};

export async function createNewFileCollection({
  name,
  directory,
  practiceID,
  parentFileCollectionID,
}: {
  name: string;
  directory: Directory;
  practiceID: number;
  parentFileCollectionID: number;
}) {
  const newCollection = await API.FileCollections.createNewFileCollection({
    name,
    practiceID,
    parentFileCollectionID,
  });
  let childFiles = 0;
  let childFileCollections = 0;
  Object.keys(directory).forEach((key) => {
    if (directory[key] instanceof File) childFiles++;
    else childFileCollections++;
  });
  return {
    ...newCollection,
    childFiles,
    childFileCollections,
  };
}
