import { twMerge } from 'tailwind-merge';
import Title from '@/components/Title';
import NewFileMenuButton from '@/components/NewFileMenuButton';
import ExpandableSearch from '@/components/ExpandableSearch';
import { classes } from './utils';

export type FileCollectionHeaderProps = {
  isFetchingData: boolean;
  fileCollectionID?: number;
  search: string;
  onSearch: (search: string) => void;
};

export default function FileCollectionHeader({
  isFetchingData,
  fileCollectionID,
  search,
  onSearch,
}: FileCollectionHeaderProps) {
  const handleSearchUpdate = (val: string) => onSearch(val);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titlesWrapper}>
        <Title>Files</Title>
      </div>
      <div
        className={twMerge(
          classes.buttonsWrapper,
          isFetchingData && classes.disabled
        )}>
        <ExpandableSearch
          className={classes.search}
          value={search}
          onChange={handleSearchUpdate}
          onClose={() => handleSearchUpdate('')}
        />
        <NewFileMenuButton parentFileCollectionID={fileCollectionID} />
      </div>
    </div>
  );
}
